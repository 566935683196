<template>
  <div class="wa__container">
    <main class="mb-10">
      <v-row class="h-inherit">
        <!-- <v-col cols="12" lg="6" class="position-relative d-none d-lg-block">
          <div
            class="auth__image"
            :style="{ backgroundImage: `url(${image})` }"
          >
            <div class="profile__image--label text-uppercase position-absolute">
              our super model:
            </div>
            <div class="profile__image--text position-absolute">
              patrick dexter
            </div>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" lg="6"> -->
        <v-col cols="12">
          <div class="d-flex justify-space-between pa-2 pt-5">
            <BrandName />
            <v-chip label text-color="black" @click="changeLang">
              {{ $_getLocale() === "jp" ? "EN" : "JP" }}</v-chip
            >
          </div>
          <RegisterSection :form="form" />
        </v-col>
      </v-row>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import RegisterImage from "../assets/610972397be91.jpg";
import BrandName from "../components/Global/Header/BrandName.vue";
import RegisterSection from "@/components/Global/Section/RegisterSection";
export default {
  components: { BrandName, RegisterSection },
  data: () => ({
    image: RegisterImage,
    form: {},
  }),
  methods: {
    changeLang() {
      this.$_changeLocalePage();
      this.form = {};
    },
  },
};
</script>
<style>
.wa__container {
  max-width: 100% !important;
  /* padding: 0 !important; */
}
.h-inherit {
  min-height: inherit;
}
.auth__image {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.h-50 {
  height: 50px;
}

.profile__image--text {
  bottom: 50px;
  left: 70px;
  font-size: 17px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
  color: var(--color-black);
}

.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-black);
  bottom: 70px;
  left: 70px;
}

.wa__register {
  width: 100%;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin-top: 80px;
  text-align: center;
}

.wa__register__form {
  width: 30%;
  margin: 0 auto;
}

.btn-register {
  font-size: 14px !important;
  padding: 0 60px !important;
  letter-spacing: 3px !important;
  float: right;
}

.btn-return {
  font-size: 15px !important;
  letter-spacing: 3px !important;
}

.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}
.prefrred__lang {
  font-family: "montserrat-light", sans-serif;
  font-size: 14px;
  color: #acacac;
  text-align: left;
  letter-spacing: 1px;
}
.btn-language {
  font-family: "montserrat-regular";
  color: #c3c3c3 !important;
  border: 1px solid #c3c3c3 !important;
  font-weight: bold;
  border-radius: 5px !important;
}
.active {
  border: 1px solid var(--color-black) !important;
  font-family: "montserrat" !important;
}
.profile__image--text {
  bottom: 50px;
  left: 70px;
  font-size: 17px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
  color: var(--color-white);
}

.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-white);
  bottom: 70px;
  left: 70px;
}
/* media query */
@media screen and (max-width: 1580px) {
  .wa__register__form {
    width: 40%;
  }
}
@media screen and (max-width: 1264px) {
  .wa__register__form {
    width: 80%;
  }
}
@media screen and (max-width: 960px) {
  .wa__register__form {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .btn-return {
    font-size: 10px !important;
    letter-spacing: 2px !important;
  }
  .btn-register {
    font-size: 12px !important;
    padding: 0 60px !important;
    width: -webkit-fill-available;
    width: 100% !important;
    letter-spacing: 2px !important;
  }
}
</style>
